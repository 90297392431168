import React from 'react';
import { Link } from 'react-router-dom';
import setting from './../../../../Assets/images/New York/Slider/setting1.webp';
import {BsArrowRight} from 'react-icons/bs';

const CreateOwnDiamond = () => {
  return (
    <>
        <section className='create_own_diamond_ring'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-7 align-self-center'>
                        <div className='right-content'>
                        <h6>Create Your Own</h6>
                            <h2> Diamond Ring</h2>
                            <img className='d-md-none d-block' src={setting} alt="" />
                            <p>Select a setting and choose a diamond to create your own diamond engagement ring.</p>
                            <p className='mb-2'><strong>Need help?</strong> We're always here</p>
                            
                            <div className='btn-groups d-flex justify-content-start ole_ring_builder_btn'>
                                <Link to="/ringsettings" className='btn outline-btn'>Start with a setting <BsArrowRight /></Link>
                                <Link to="/diamonds" className='btn outline-btn'>Start with a Diamond <BsArrowRight /></Link>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-5 text-center d-md-block d-none'>
                        <img className='' src={setting} alt="" />
                    </div>
                </div>
            </div>
        </section>
    </>

  )
}

export default CreateOwnDiamond;