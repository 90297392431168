import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Watch from '../../../../Assets/images/oles-img/custom-design1.webp';
import Jewelery from '../../../../Assets/images/oles-img/sell-gold.webp';
import StylishBorder from './../../../../Assets/images/New York/Slider/line-black.png';



const CustomEstate = () => {

    return (
        <>

            <section className="jewelry_repair_section">
                <Container>
<Row>
    <Col sm={12} md={12} lg={12}>
          <div className='title-box text-center mb-5'>
                    <h2>Ole's Jewelry Ave Major Services</h2>
                    <img src={StylishBorder} alt='border image' />
                </div>
    </Col>
</Row>
                    <Row className='justify-content-center'>
                        <Col lg={6} md={6}>
                            <div className="j_reapair_catelogbox">
                            <Link to="/custom-design">    <img src={Watch} alt="custom-img" /> </Link>
                                <div className='j_repair_text'>
                                    <h3><Link to="/custom-design"> Custom Design </Link> </h3>
                                <p>Custom Design is a testament to personal style and individuality,
where dreams are transformed into wearable art.</p>
                                    <Link className='j_repair_btn' to="/custom-design"> Get Started </Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className="j_reapair_catelogbox">
                            <Link to="/jewelry/fine-jewelry">    <img src={Jewelery} alt="et-img" /> </Link>
                                <div className='j_repair_text'>
                                    <h3> <Link to="#"> Sell Your Gold </Link></h3>
                                     <p>Gold is a wise financial choice! Sell gold with us to avail the best offers tailor-made for you.</p>
                                    <Link className='j_repair_btn' to="#"> Sell Now </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>

        </>
    );
}
export default CustomEstate;