import React from "react";
import StylishBorder from "./../../../../Assets/images/New York/Slider/line-black.png";
import Star from "./../../../../Assets/images/New York/Slider/str.svg";
import GoogleReview from "./../../../../Assets/images/New York/Slider/google-reviews.jpg";
import YelpReview from "./../../../../Assets/images/New York/Slider/yelp-reviews.jpg";
import IvouchReview from "./../../../../Assets/images/New York/ivouch-reviews.jpg";
import ReactOwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { ArrowBackIos, ArrowRightAlt } from "@material-ui/icons";
import { FaQuoteLeft } from "react-icons/fa";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Rating } from "@mui/material";

const WhatPeopleSay = () => {
  return (
    <>
      <section className="testimonials">
        <div className="container">
          <div className="title-box text-center pb-4">
            <h2>WHAT PEOPLE SAY</h2>
            <img src={StylishBorder} alt="" />
          </div>

          <Row>
            <Col sm={12} md={6}>
            <div className="google-review review-block">
                  <div className="header text-center">
                    <img src={GoogleReview} alt="" />
                  </div>
                  <div className="body text-center position-relative">
                    <div className="testimonial-icon">
                      <FaQuoteLeft />
                    </div>
                    <ReactOwlCarousel
                      className="owl-main owl-theme"
                      nav={true}
                      dots={true}
                      navText={[ArrowBackIos, ArrowRightAlt]}
                      items={1}
                      loop
                    >
                      <div className="item">
                        <p>
                          <span>
                          Ole's is the best jewelry store in South Florida. They sell gold chain, bridal rings, diamonds, gifts, and watches. Tons of brands and items for every budget. The owner Olessi is extremely friendly and trustworthy
                          </span>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=oles-jewelry+google+reviews&sca_esv=576472481&rlz=1C1CHZN_enIN1077IN1077&ei=ZPY4ZeS-NY6t4-EPp6aQ4AE&oq=oles-jewelry+google&gs_lp=Egxnd3Mtd2l6LXNlcnAiE29sZXMtamV3ZWxyeSBnb29nbGUqAggAMgcQIRigARgKMgcQIRigARgKSL0mUMoEWMgZcAF4AJABAJgBhQKgAdEKqgEFMC41LjK4AQPIAQD4AQHCAgkQABgeGLADGArCAgsQABiKBRiGAxiwA8ICBhAAGB4YCsICCBAAGIoFGIYDwgIIECEYFhgeGB3iAwQYASBBiAYBkAYG&sclient=gws-wiz-serp#lrd=0x88d9bec5fc96258b:0x5480b652f3591ddc,1,,,,"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                        <Rating name="1.1" defaultValue={5} precision={1.1} readOnly />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=oles-jewelry+google+reviews&sca_esv=576472481&rlz=1C1CHZN_enIN1077IN1077&ei=ZPY4ZeS-NY6t4-EPp6aQ4AE&oq=oles-jewelry+google&gs_lp=Egxnd3Mtd2l6LXNlcnAiE29sZXMtamV3ZWxyeSBnb29nbGUqAggAMgcQIRigARgKMgcQIRigARgKSL0mUMoEWMgZcAF4AJABAJgBhQKgAdEKqgEFMC41LjK4AQPIAQD4AQHCAgkQABgeGLADGArCAgsQABiKBRiGAxiwA8ICBhAAGB4YCsICCBAAGIoFGIYDwgIIECEYFhgeGB3iAwQYASBBiAYBkAYG&sclient=gws-wiz-serp#lrd=0x88d9bec5fc96258b:0x5480b652f3591ddc,1,,,,"
                          >
                        Mitchell Waksman
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                          Very rude. My mom was just trying to ask about getting a ring resized and if they do it a specific way and the owner in guessing snapped at her saying she should do it herself.
                          </span>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?q=oles-jewelry+google+reviews&sca_esv=576472481&rlz=1C1CHZN_enIN1077IN1077&ei=ZPY4ZeS-NY6t4-EPp6aQ4AE&oq=oles-jewelry+google&gs_lp=Egxnd3Mtd2l6LXNlcnAiE29sZXMtamV3ZWxyeSBnb29nbGUqAggAMgcQIRigARgKMgcQIRigARgKSL0mUMoEWMgZcAF4AJABAJgBhQKgAdEKqgEFMC41LjK4AQPIAQD4AQHCAgkQABgeGLADGArCAgsQABiKBRiGAxiwA8ICBhAAGB4YCsICCBAAGIoFGIYDwgIIECEYFhgeGB3iAwQYASBBiAYBkAYG&sclient=gws-wiz-serp#lrd=0x88d9bec5fc96258b:0x5480b652f3591ddc,1,,,,"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                        <Rating name="5" defaultValue={5} precision={5} readOnly />
                        </div>
                        <h3>
                          <a
                            target="_blank"
                            to="https://www.google.com/search?q=oles-jewelry+google+reviews&sca_esv=576472481&rlz=1C1CHZN_enIN1077IN1077&ei=ZPY4ZeS-NY6t4-EPp6aQ4AE&oq=oles-jewelry+google&gs_lp=Egxnd3Mtd2l6LXNlcnAiE29sZXMtamV3ZWxyeSBnb29nbGUqAggAMgcQIRigARgKMgcQIRigARgKSL0mUMoEWMgZcAF4AJABAJgBhQKgAdEKqgEFMC41LjK4AQPIAQD4AQHCAgkQABgeGLADGArCAgsQABiKBRiGAxiwA8ICBhAAGB4YCsICCBAAGIoFGIYDwgIIECEYFhgeGB3iAwQYASBBiAYBkAYG&sclient=gws-wiz-serp#lrd=0x88d9bec5fc96258b:0x5480b652f3591ddc,1,,,,"
                          >
                    Duong Truong
                          </a>
                        </h3>
                      </div>

                    
                    </ReactOwlCarousel>
                  </div>
                </div>
            </Col>
            <Col sm={12} md={6}>
            <div className="yelp-review review-block">
                  <div className="header text-center">
                    <img src={YelpReview} alt="" />
                  </div>
                  <div className="body text-center position-relative">
                    <div className="testimonial-icon">
                      <FaQuoteLeft />
                    </div>
                    <ReactOwlCarousel
                      // className="owl-main owl-theme"
                      // navText={[ArrowBackIos, ArrowRightAlt]}
                      // items={1}
                      // nav={true}
                      // loop
                      className="owl-main owl-theme"
                      nav={true}
                      dots={true}
                      navText={[ArrowBackIos, ArrowRightAlt]}
                      items={1}
                      loop
                      responsive={{
                        0: {
                          nav: true,
                        },

                        768: {
                          nav: true,
                        },
                      }}
                    >
                      <div className="item">
                        <p>
                          <span>
                          This is a nice family establishment and I've been going there for years for repairs and small stuff. However, I was in need of having a ring re-made and Ole assured me she could make an identical copy and upgrade the stones.
                          </span>
                          <a target="_blank" href="https://www.yelp.com/biz/oles-jewelry-ave-miami-3">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                        <Rating name="1" defaultValue={5} precision={5} readOnly />
                        </div>
                        <h3>
                          <a target="_blank" href="https://www.yelp.com/biz/oles-jewelry-ave-miami-3">
                          Frank P.
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                          I was at Ole's yesterday to see how much it would cost to get some silver jewelry clean. I showed the owner a very simple bracelet, the matching ring, and a pair of plain hoop earrings.
                          </span>
                          <a target="_blank" href="https://www.yelp.com/biz/DIamond World-jewelers-lincolnwood">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                        <Rating name="2" defaultValue={5} precision={2} readOnly />
                        </div>
                        <h3>
                          <a target="_blank" href="https://www.yelp.com/biz/DIamond World-jewelers-lincolnwood">
                          Irma F.
                          </a>
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                          This is the WORSE jewerly shop in Miami. The lady attending the store which I hope she is not the owner is the most rude person to the clients.
                          </span>
                          <a target="_blank" href="https://www.yelp.com/biz/DIamond World-jewelers-lincolnwood">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                        <Rating name="3" defaultValue={5} precision={3} readOnly />
                        </div>
                        <h3>
                          <a target="_blank" href="https://www.yelp.com/biz/DIamond World-jewelers-lincolnwood">
                          Andy S.
                          </a>
                        </h3>
                      </div>

                    </ReactOwlCarousel>
                  </div>
                </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default WhatPeopleSay;
