import React from 'react'
import { Link } from 'react-router-dom';
import StylishBorder from './../../../../Assets/images/New York/Slider/line-black.png';

export const InstagramFeed = () => {
  return (
    <>
        <section className='instafeed  pt-4'>
            <div className='container-fluid'>
                <div className='title-box text-center mb-2'>
                    <h2 className='mb-3'>Social Media</h2>
                    <img src={StylishBorder} alt='border image' className='mb-4' />
                </div>

                {/* <p className='text-center'>Follow <a target="_blank" href="https://instagram.demobw.live/diamondworld/" className='text-dark'>@lakeHillier</a> on Instagram to get inspired</p> */}

                <iframe src="https://instagram.demobw.live/olesjewelryavenue/" frameborder="0" width="100%" class="insta-iframe"></iframe>
            </div>
        </section>
    </>
  )
}
