import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import AboutUsImg from '../../../../Assets/images/about-us-img1.webp'
import { NavLink } from 'react-router-dom'

const Aboutus = () => {
  return (
    <>
      <div className='oles_about_us_section'>
          <Container>
            <Row>
                <Col sm={12} md={6} lg={6}>
                    <div className='oles_about_img'>
                        <Image src={AboutUsImg} className="img-fluid"/>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={6} className='d-flex align-items-center justify-content-center'>
                    <div className='oles_about_text'>
                        <h2>About Us</h2>
                        <p
                        >Welcome to our exquisite world of diamond jewelry, where timeless elegance
                        meets expert craftsmanship. At Ole’s Diamond, we are passionate about bringing
                        the most stunning, ethically sourced diamonds to adorn your life's most special
                        moments. Our commitment to quality, precision, and the artistry of jewelry design
                        ensures that every piece in our collection is a true masterpiece. With a deep
                        appreciation for the rarity and beauty of diamonds, we're dedicated to helping
                        you celebrate life's milestones with unparalleled grace and brilliance. Explore
                        our range of diamond jewelry, and let these extraordinary gemstones illuminate
                        your story.</p>
                        <div className='ole_about_btn_box'>
                             <NavLink className='j_repair_btn mt-3' to="/our-story"> Know More </NavLink>
                        </div>
                    </div>
                </Col>
            </Row>
          </Container>
      </div>
    </>
    
  )
}

export default Aboutus
