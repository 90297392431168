import React from 'react';
import { useSelector } from 'react-redux';
import "./../../../Assets/css/New york/home.css";
import "./../../../Assets/css/New york/MegaMenu.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BannerSlider from './Home/Slider';
import CreateOwnDiamond from './Home/CreateOwnDiamond';
import OurCollection from './Home/OurCollection';
import { ShopDiamondShape } from './Home/ShopDiamondShape';
import WhatPeopleSay from './Home/WhatPeopleSay';
import { InstagramFeed } from './Home/InstagramFeed';
import { Newsletter } from './Home/Newsletter';
import BestSellingProduct from './Home/BestSellingProduct';
import { client_name } from "../../../Helpers/request";
import { Helmet } from 'react-helmet';
import CustomEstate from './Home/CustomEstate';
import SellDiamond from './Home/SellDiamond';
import Aboutus from './Home/Aboutus';

const Home = () => {
  const metaDetails = useSelector(state => state.persistedReducer.meta.meta)

  return (
 
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content={client_name.c_name} />
      </Helmet>
      {/* <Header/> */}
      <BannerSlider />
      <CreateOwnDiamond /> 
      <CustomEstate/>
      <SellDiamond/>
      <OurCollection />
      {/* <BestSellingProduct /> */}
      <ShopDiamondShape />
   
      <WhatPeopleSay />
      <Aboutus/>
      <InstagramFeed />
      <Newsletter />
    </> 
  )
}

export default Home;