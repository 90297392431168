import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import SellDiamondImg from "../../../../Assets/images/New York/Sell-Diamond.jpg"
import SellDiamondImg from "../../../../Assets/images/oles-img/exclusive-waches.webp";
import SellDiamondImgt from "../../../../Assets/images/oles-img/exclusive-waches-tab1.webp";

// import SellDiamondImgMobile from "../../../../Assets/images/New York/Sell-Diamond-Mobile.jpg"
import SellDiamondImgMobile from "../../../../Assets/images/oles-img/exclusive-waches-mobile1.webp";
import {
  isMobileOnly,
  isMobile,
  isTablet,
  isDesktop,
} from "react-device-detect";

const SellDiamond = () => {
  return (
    <>
      <section className="dw_sell_section pt-0">
        {isMobileOnly ? (
          <img
            className="img-fluid"
            src={SellDiamondImgMobile}
            alt="sell_img"
          />
        ) : isTablet ? (
          <img className="img-fluid" src={SellDiamondImgt} alt="sell_img" />
        ) : (
          <img className="img-fluid" src={SellDiamondImg} alt="sell_img" />
        )}
        {/* {isTablet ?   <img className='img-fluid' src={SellDiamondImgt} alt="sell_img" /> :   ""} */}
        <div className="dw_sellDiamond_content">
          <h6>Exclusive</h6>
          <h3>Luxurious Watches</h3>
          <p>
            Discover the perfect timepiece at Ole's Jewelry Ave. Our curated
            selection of watches combines precision engineering and style,
            offering a variety of options to suit every taste. From classic
            elegance to modern sophistication, we provide a range of
            high-quality watches to elevate your look and ensure you're always
            on time, in style.
          </p>
          <Link className="dw_sell_d_btn" to="/watches">
            {" "}
            Shop Watches
          </Link>
        </div>
      </section>
    </>
  );
};
export default SellDiamond;
