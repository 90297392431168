import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import {base_url, postHeader, currencycode} from '../../../../Helpers/request'

export const Newsletter = () => {

  const history = useHistory();
    const [newsletteremail, setNewsletteremail] = useState("")
    const [loader,setLoader] = useState(false)
    const newsletter = (e) => {
        e.preventDefault();
        setLoader(true)
        const data = {
            currency_code:currencycode,
            email: newsletteremail
        }
        axios.post(base_url + '/common/subscribe_newsletter', data, {
            headers: postHeader
        })
            .then(res => {
                if (res.data.status == 1) {
                    setLoader(false)
                    setNewsletteremail('');
                    return swal(res.data.message, "", "success");
                } else {
                    setLoader(false)
                    return swal(res.data.message, "", "error");
                }
            })
            .catch((error) => {
                setLoader(false)
                console.log(error);
            });
    }
  return (
    <>
        <section className='signup_section'>
            <div className='container text-center'>
                <h3 className='mb-3'>Sign up to be a Ole's Jewelry Ave Insider</h3>
                <p className='mb-3'>Become a preferred subscriber and get a special offer on your purchase.</p>
                <Form className='form' onSubmit={newsletter}>
                <InputGroup className='justify-content-center'>
                  <Form.Control
                    placeholder="EMAIL ADDRESS"
                    aria-label="EMAIL ADDRESS"
                    aria-describedby="basic-addon2"
                    value={newsletteremail}
                    required
                    onChange={(e) => setNewsletteremail(e.target.value)}
                  />
                  <Button variant="outline-secondary" type="submit">SUBSCRIBE</Button>
                </InputGroup>
                </Form>
                <p className='desc-dark'>By signing up you confirm that you have read the <Link to="#" className='text-dark'>Privacy Policy</Link> and agree that your email will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing.</p>
            </div>
        </section>
    </>
  )
};
